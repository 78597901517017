<template>
  <div class="flex items-center">
    <ion-toggle :checked="false" :disabled="true" class="ion-toggle-default pr-3" v-show="props.reset"></ion-toggle>
    <ion-toggle :checked="props.checked" :disabled="props.disabled" class="ion-toggle-default pr-3"
      @ionChange="toggleChange($event)" v-show="!props.reset"></ion-toggle>
    <ion-label class="text-md font-bold ion-text-brand" :class="props.reset ? 'opacity-30' : ''">
      <span :class="labelStyle(props.label)">{{ props.label }}</span>
      <ion-icon src="/assets/general/info.svg" class="w-[12px] h-[12px] pl-2 -mb-[2px]" :id="`open-modal-${props.name}`"
        v-if="props.description"></ion-icon>
    </ion-label>
    <ion-modal class="modal-center" :ref="`modal-${props.name}`" :trigger="`open-modal-${props.name}`"
      backdrop-dismiss="false" v-if="props.description && !props.reset">
      <ion-card class="bg-white m-0 p-0 rounded-xl">
        <ion-card-content class="p-3 pt-2">
          <ion-grid>
            <ion-row>
              <ion-col class="flex items-center justify-between">
                <ion-icon src="/assets/general/info.svg" class="w-[12px] h-[12px] pr-2 -mb-[2px] opacity-50" />
                <h3 class="capitalize ion-text-brand text-center">
                  <strong>{{ props.label }}</strong>
                </h3>
                <ion-icon src="/assets/credit-score/close.svg" class="p-1 float-right cursor-pointer" @click="close" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="text-xs">
                <ion-text class="block leading-[18px] mt-3 px-6 text-center">
                  <span v-html="props.description"></span>
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-modal>
  </div>
</template>
<script setup>
import { modalController } from '@ionic/vue';
const props = defineProps(['name', 'label', 'checked', 'description', 'disabled', 'reset']);
const emit = defineEmits(['toggleChange']);
const toggleChange = (e) => {
  emit('toggleChange', e.target.checked);
}
const labelStyle = (val) => {
  switch (val) {
    case "sms": {
      return 'uppercase';
    }
    case 'Opt out ALL types & channels': {
      return '';
    }
    default: {
      return 'capitalize';
    }
  }
}
const close = () => {
  modalController.dismiss();
};
</script>