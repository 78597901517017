<template>
  <ion-refresher slot="fixed" @ionRefresh="refreshContent($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
</template>

<script setup>
import axios from "axios";
import { useBrowser } from "@/composables/browser";
import {
  useCustomerStore,
  useNotificationsStore,
  useLoansStore,
  useApplicationsStore,
} from "@/stores";
import { storeToRefs } from "pinia";
import { addCustomerIdToSession } from "@/composables/adjust";
import { useSecureStorage } from "@/composables/secureStorage";
import router from "@/router";

const customerStore = useCustomerStore();
const {
  selfBanned,
  environment,
  currentVersion,
  authToken,
  getCustomerParams,
} = storeToRefs(customerStore);
const {
  setSelfBanned,
  setProfile,
  updateNewCustomer,
  getFinanceFundamentals,
  updateAuth,
} = customerStore;
const notificationsStore = useNotificationsStore();
const { setNotifications } = notificationsStore;
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { urls } = useBrowser();
const { clearAllStorage } = useSecureStorage();
const refreshContent = (e) => {
  setTimeout(() => {
    axios
      .get(urls.apiCustomer + getCustomerParams.value, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + authToken.value,
        },
      })
      .then((res) => {
        setSelfBanned(res.data.customer.self_banned);
        setProfile(res.data.customer);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
        updateNewCustomer(false);
        addCustomerIdToSession(res.data.customer.id);
      })
      .then(() => {
        getFinanceFundamentals(
          urls.apiFinanceFundamentals +
            "?environment=" +
            environment.value +
            "&app_version=" +
            currentVersion.value,
          authToken.value
        );
      })
      .then(() => {
        if (selfBanned.value) {
          updateAuth(false);
          clearAllStorage();
          router.push("/start");
        }
      })
      .catch((err) => console.log(err));
    e.target.complete();
  }, 2000);
};
</script>
