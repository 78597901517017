<template>
  <ion-content>
    <div class="px-7 pt-6 max-w-xl mx-auto" :class="saving ? 'opacity-50' : ''">
      <div>
        <div>
          <ion-text class="text-sm ion-text-brand mt-1 mb-6 block">
            At Jacaranda Finance, we respect your privacy and believe in providing you with full control over how we
            communicate with you. Remember, you can update your preferences at any time.
          </ion-text>
          <div class="fadein-500">
            <communication-preferences-toggle name="opt-out-all" :label="optOutAllLabel" :checked="optOutAll"
              :description="optOutAllDescription" @toggleChange="optOutAllUpdate" :disabled="saving" />
          </div>
        </div>
        <div class="mt-8 mb-6">
          <ion-text class="text-lg font-bold ion-text-brand">Communication Types</ion-text>
          <ion-text class="text-sm ion-text-brand mt-3 mb-6 block">
            Choose the types of updates you'd like to receive, ensuring you only get the information that's important to
            you:
          </ion-text>
          <div class="ml-6" v-if="loading">
            <ion-spinner name="dots" color="primary"></ion-spinner>
          </div>
          <div class="ml-6 fadein-500" v-else>
            <communication-preferences-toggle v-for="(category, categoryIndex) in categories" :key="categoryIndex"
              :name="category.label" :label="formatStrToWord(category.label)" :checked="!category.is_opted_out"
              :description="category.description" :disabled="saving" :reset="optOutAll || optOutPause"
              @toggleChange="toggleChange($event, category, 'categories')" />
          </div>
        </div>
        <hr class="my-8 hr-grey" />
        <div class="my-6">
          <ion-text class="text-lg font-bold ion-text-brand">Communication Channels</ion-text>
          <ion-text class="text-sm ion-text-brand mt-3 mb-6 block">
            Please select which channel you'd like to subscribe/unsubscribe to:
          </ion-text>
          <div class="ml-6" v-if="loading">
            <ion-spinner name="dots" color="primary"></ion-spinner>
          </div>
          <div class="ml-6 fadein-500" v-else>
            <communication-preferences-toggle v-for="(channel, channelIndex) in channels" :key="channelIndex"
              :name="channel.label" :label="formatStrToWord(channel.label)" :checked="!channel.is_opted_out"
              :description="channel.description" :disabled="saving" :reset="optOutAll || optOutPause"
              @toggleChange="toggleChange($event, channel, 'channels')" />
          </div>
        </div>
        <hr class="my-8 hr-grey" />
        <div class="my-6">
          <ion-text class="text-lg font-bold ion-text-brand">Opt-out Period</ion-text>
          <ion-text class="text-sm ion-text-brand mt-3 mb-6 block">
            Whether you'd like to take a break from our communications or stop them altogether, you're in control.
          </ion-text>
          <div class="ml-6" v-if="loading">
            <ion-spinner name="dots" color="primary"></ion-spinner>
          </div>
          <div class="ml-6 fadein-500" v-else>
            <communication-preferences-toggle name="opt-out-pause" :label="optOutPauseLabel" :checked="optOutPause"
              :description="optOutPauseDescription" :disabled="saving || optOutAll" :reset="optOutAll" @toggleChange="optOutPauseUpdate" />
          </div>
        </div>
        <ion-text class="text-xs ion-text-brand mt-3 mb-6 block">
          Please note that Jacaranda will continue to contact you via the contact channels supplied regarding your
          active loans or products.
        </ion-text>
      </div>
    </div>
  </ion-content>
  <ion-footer>
    <div class="details-hidden details-hidden-btn"></div>
    <div class="py-0 text-center" v-if="marketingPreferencesErrMsg">
      <ion-text class="ion-text-red block text-xs flex items-center justify-center">
        <ion-icon src="/assets/general/notice-danger.svg" class="w-[18px] h-[18px] mr-3"></ion-icon>
        <span>{{ marketingPreferencesErrMsg }}</span>
      </ion-text>
    </div>
    <div class="pb-3">
      <div class="px-7 max-w-xl mx-auto">
        <button expand="block" color="secondary" class="button-purple" @click="saveUpdates" :disabled="saving">
          <div class="flex items-center justify-center">
            <ion-spinner name="crescent" class="m-2" v-if="saving"></ion-spinner>
            <strong>Save Updates</strong>
          </div>
        </button>
      </div>
    </div>
  </ion-footer>
</template>

<script setup>
import CommunicationPreferencesToggle from './CommunicationPreferencesToggle.vue';
import { ref, computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import { useMixed } from '@/composables/mixed';
import { useBrowser } from "@/composables/browser";
import { useRoute } from 'vue-router';
import router from '@/router';
const route = useRoute();
const watchPath = computed(() => route.path == '/tabs/communication-preferences');
const { formatStrToWord, formatDOB } = useMixed();
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const { environment, currentVersion, deviceID, marketingPreferences, marketingPreferencesErrMsg } = storeToRefs(customerStore);
const { getMarketingPreferences, setMarketingPreferences, setMarketingPreferencesPause, setMarketingPreferencesPauseUntil, setMarketingPreferencesPauseDuration, setMarketingPreferencesErrMsg } = customerStore;
const loading = ref(false);
const saving = ref(false);
const originalPreference = ref({});
const categories = ref([]);
const channels = ref([]);
const payload = ref({});
const toggleChange = (event, item, type) => {
  const index = [...payload.value.preferences[type]].findIndex((_) => _.label == item.label);
  payload.value.preferences[type][index].is_opted_out = optOutAll.value ? optOutAll.value : !event;
  payload.value.preferences[type][index].opted_out_until = optOutPause.value ? optOutUntil.value : null;
}
const optOutAll = ref(false);
const optOutAllLabel = ref('Opt out ALL types & channels');
const optOutAllDescription = ref(null);
const optOutAllUpdate = (event) => {
  optOutAll.value = event;
  if (optOutAll.value) {
    [...categories.value, ...channels.value, ...payload.value.preferences.categories, ...payload.value.preferences.channels].map((item) => {
      item.is_opted_out = optOutAll.value;
    });
  } else {
    [...categories.value, ...channels.value].map((item) => {
      item.is_opted_out = [...originalPreference.value.fields.categories, ...originalPreference.value.fields.channels].filter(val => val.label == item.label)[0].is_opted_out;
    });
  }
};
const optOutUntil = ref(null);
const optOutPause = ref(false);
const optOutPauseDuration = ref(null);
const optOutPauseLabel = computed(() => `Pause for ${optOutPauseDuration.value} months`);
const optOutPauseDescription = ref(null);
const optOutPauseUpdate = (event) => {
  optOutPause.value = event;
  if (optOutPause.value) {
    [...categories.value, ...channels.value, ...payload.value.preferences.categories, ...payload.value.preferences.channels].map((item) => {
      item.is_opted_out = optOutPause.value;
      item.opted_out_until = optOutUntil.value;
    });
  } else {
    [...categories.value, ...channels.value, ...payload.value.preferences.categories, ...payload.value.preferences.channels].map((item) => {
      item.is_opted_out = [...originalPreference.value.fields.categories, ...originalPreference.value.fields.channels].filter(val => val.label == item.label)[0].is_opted_out;
      item.opted_out_until = null;
    });
  }
};
const initPreferences = () => {
  originalPreference.value = JSON.parse(JSON.stringify(marketingPreferences.value)); // needed when user cancel OptOutAll
  categories.value = [...marketingPreferences.value.fields.categories];
  channels.value = [...marketingPreferences.value.fields.channels];
  optOutUntil.value = formatDOB(marketingPreferences.value.config.snooze.date);
  optOutPauseDuration.value = marketingPreferences.value.config.snooze.duration;
}
const initPayload = () => {
  payload.value = {
    "preferences": {
      "categories": [...categories.value].map((category) => ({
        "label": category.label,
        "is_opted_out": category.is_opted_out,
        "opted_out_until": null

      })),
      "channels": [...channels.value].map((category) => ({
        "label": category.label,
        "is_opted_out": category.is_opted_out,
        "opted_out_until": null
      })),
    }
  }
};
const setPauseStatus = () => {
  setMarketingPreferencesPause(optOutPause.value);
  setMarketingPreferencesPauseDuration(optOutPause.value ? optOutPauseDuration.value : null);
  setMarketingPreferencesPauseUntil(optOutPause.value ? optOutUntil.value : null);
};
const saveUpdates = () => {
  saving.value = true;
  setMarketingPreferences(urls.apiMarketingPreferences, {
    ...payload.value,
    device_id: deviceID.value,
    environment: environment.value,
  }, () => {
    setPauseStatus();
    setTimeout(() => {
      saving.value = false;
      router.push({ name: 'CommunicationPreferencesUpdated' });
    }, 500);
  });
};
const resetMarketingPreferences = () => {
  setMarketingPreferencesPause(null);
  setMarketingPreferencesPauseDuration(null);
  setMarketingPreferencesPauseUntil(null);
  setMarketingPreferencesErrMsg(null);
};
const init = () => {
  loading.value = true;
  resetMarketingPreferences();
  getMarketingPreferences(urls.apiMarketingPreferences + '?environment=' + environment.value + '&app_version=' + currentVersion.value, () => {
    initPreferences();
    initPayload();
    setTimeout(() => {
      loading.value = false;
    }, 500);
  });
}
onMounted(() => {
  init();
});
watch(() => route.path, () => {
  if (watchPath.value) {
    init();
  }
}, { deep: true });
</script>