import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import LoadScript from "vue-plugin-load-script";
import VueNumberFormat from "vue-number-format";
import "./registerServiceWorker";
import {
  IonApp,
  IonRouterOutlet,
  IonicVue,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonSpinner,
  IonImg,
  IonText,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonRange,
  IonRadio,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonSkeletonText,
  IonInput,
  IonNote,
  IonTabs,
  IonTabButton,
  IonTabBar,
  IonMenu,
  IonMenuToggle,
  IonMenuButton,
  IonList,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonActionSheet,
  IonTextarea,
  IonDatetime,
  IonDatetimeButton,
  IonCheckbox,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonToggle,
  IonFooter
} from "@ionic/vue";

/* Ionic Core CSS */
import "@ionic/vue/css/core.css";

/* Ionic Basic CSS */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme Variables */
import "./theme/variables.css";
import "./theme/style.css";
import "./theme/video-js.css";

const pinia = createPinia();
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)
  .use(LoadScript)
  .use(VueNumberFormat, {
    prefix: "$ ",
    decimal: ".",
    thousand: ",",
    precision: 2,
  });

/* Global Registration */
app.component("ion-app", IonApp);
app.component("ion-router-outlet", IonRouterOutlet);
app.component("ion-page", IonPage);
app.component("ion-header", IonHeader);
app.component("ion-content", IonContent);
app.component("ion-toolbar", IonToolbar);
app.component("ion-title", IonTitle);
app.component("ion-button", IonButton);
app.component("ion-buttons", IonButtons);
app.component("ion-icon", IonIcon);
app.component("ion-spinner", IonSpinner);
app.component("ion-img", IonImg);
app.component("ion-text", IonText);
app.component("ion-modal", IonModal);
app.component("ion-card", IonCard);
app.component("ion-card-content", IonCardContent);
app.component("ion-card-header", IonCardHeader);
app.component("ion-card-subtitle", IonCardSubtitle);
app.component("ion-back-button", IonBackButton);
app.component("ion-grid", IonGrid);
app.component("ion-row", IonRow);
app.component("ion-col", IonCol);
app.component("ion-range", IonRange);
app.component("ion-radio-group", IonRadioGroup);
app.component("ion-radio", IonRadio);
app.component("ion-item", IonItem);
app.component("ion-label", IonLabel);
app.component("ion-skeleton-text", IonSkeletonText);
app.component("ion-input", IonInput);
app.component("ion-note", IonNote);
app.component("ion-tabs", IonTabs);
app.component("ion-tab-bar", IonTabBar);
app.component("ion-tab-button", IonTabButton);
app.component("ion-menu", IonMenu);
app.component("ion-menu-toggle", IonMenuToggle);
app.component("ion-menu-button", IonMenuButton);
app.component("ion-list", IonList);
app.component("ion-select", IonSelect);
app.component("ion-select-option", IonSelectOption);
app.component("ion-popover", IonPopover);
app.component("ion-action-sheet", IonActionSheet);
app.component("ion-textarea", IonTextarea);
app.component("ion-datetime", IonDatetime);
app.component("ion-datetime-button", IonDatetimeButton);
app.component("ion-checkbox", IonCheckbox);
app.component("ion-refresher", IonRefresher);
app.component("ion-refresher-content", IonRefresherContent);
app.component("ion-searchbar", IonSearchbar);
app.component("ion-toggle", IonToggle);
app.component("ion-footer", IonFooter);
router.isReady().then(() => {
  app.mount("#app");
  if (Capacitor.isNativePlatform()) {
    if (typeof window.screen.orientation.lock !== "undefined") {
      window.screen.orientation.lock("portrait");
    }
  }
});
