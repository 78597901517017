<template>
  <swiper :breakpoints="breakpoints" :modules="modules" :slides-per-view="1" :pagination="pagination"
    :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange" class="pb-3 swiper-start fadein">
    <swiper-slide v-for="slide in slides" :key="slide.id">
      <div class="fadein-900 text-center">
        <h2 class="font-bold px-3 text-white" :class="styleTitle" v-html="slide.title" v-if="slide.title">
        </h2>
        <div class="fadein-900">
          <img loading="lazy" :src="slide.image" class="mx-auto slider"
            :class="screenSizeSmall ? slide.imageStyleSmall : slide.imageStyle" />
          <img loading=" lazy" :src="slide.loading" class="hidden" v-if="slide.loading" />
          {{ slide.screenSize }}
        </div>
        <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        <h2 class="font-black px-3" :class="styleSubtitle" v-html="slide.subtitle" v-if="slide.subtitle">
        </h2>
        <p class="ion-text-white px-3" :class="styleText" v-html="slide.text" v-if="slide.text"></p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useConfigStore } from '@/stores';
import { storeToRefs } from 'pinia';
import 'swiper/css';
import 'swiper/css/pagination';
const configStore = useConfigStore();
const { screenSizeSmall } = storeToRefs(configStore);
const styleTitle = computed(() => screenSizeSmall.value ? 'text-[24px]' : 'text-[32px] pt-6');
const styleTitlePurple = 'w-[230px] text-[#9B5CFF] bg-[#E6D6FF] inline-block my-1 py-1 px-3 rounded-[12px]';
const styleTitleYellow = 'w-[230px] text-black bg-[#FEE113] inline-block my-1 py-1 px-3 rounded-[12px]';
const styleSubtitle = computed(() => screenSizeSmall.value ? 'text-[36px] leading-[100%]' : 'text-[48px] leading-[100%]');
const styleText = computed(() => screenSizeSmall.value ? 'text-[12px] pt-1' : 'text-[14px] leading-[140%] pt-3');
const emit = defineEmits(['activeIndex']);
const modules = [Pagination];
const breakpoints = {
  640: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 1,
  },
  1280: {
    slidesPerView: 1,
  },
};
const pagination = {
  clickable: true
};
const slides = ref([
  {
    id: 1,
    title: `<span class="block ${screenSizeSmall.value ? 'text-[36px] leading-[110%] mt-3 mb-6' : 'text-[42px] leading-[120%] my-12'}">We can make<br/><span class="ion-text-yellow">loans happen</span><br/>when the<br/><span class="ion-text-yellow">banks won't.</span></span>`,
    subtitle: '',
    text: '',
    image: '/assets/carousel/slide-1.png',
    imageStyle: 'h-[70px]',
    imageStyleSmall: 'h-[70px]',
    loading: '/assets/carousel/slide-2.gif',
  },
  {
    id: 2,
    title: null,
    subtitle: `<span class="text-white">Personal<br/>Loans</span>`,
    text: 'Check if you qualify without<br/>impacting your credit score',
    image: '/assets/carousel/slide-2.gif',
    imageStyle: 'h-[200px] my-2',
    imageStyleSmall: 'h-[170px] mt-0  mb-2',
    loading: '/assets/carousel/slide-3.gif',
  },
  {
    id: 3,
    title: `<span class="${styleTitlePurple}">Track your</span><br/><span class="${styleTitleYellow} -mb-6">Credit Score</span><br/><span class="text-lg block font-normal italic mt-3">for Free</span>`,
    subtitle: null,
    text: null,
    image: '/assets/carousel/slide-3.gif',
    imageStyle: 'h-[260px] -mt-6',
    imageStyleSmall: 'h-[200px] -mt-6',
    loading: '/assets/carousel/slide-4.gif',
  },
  {
    id: 4,
    title: null,
    subtitle: null,
    text: `<span class="italic">Get access for free to build<br/>your finance knowledge</span>`,
    image: '/assets/carousel/slide-4.gif',
    imageStyle: 'h-[260px] mt-12 -mb-8',
    imageStyleSmall: 'h-[230px] mt-0 -mb-6',
    loading: '/assets/carousel/slide-1.png',
  }
]);
const onSwiper = (swiper) => {
  emit('activeIndex', swiper.activeIndex + 1);
};

const onSlideChange = (swiper) => {
  emit('activeIndex', swiper.activeIndex + 1);
}
</script>

<style scoped>
.slider {
  min-height: 60px;
}

.swiper-lazy-preloader {
  opacity: 0.6;
}
</style>