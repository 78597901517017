<template>
  <div :class="props.loading || (props.match && props.confirmed) ? 'opacity-30' : ''">
    <div class="max-w-[300px] mx-auto mb-12">
      <div class="indicators-keypad">
        <div class="inline-block" v-if="visible">
          <span v-for="key in [...keypadEntered]" class="key" :key="key">{{ key }}</span>
        </div>
        <div class="inline-block" :class="shake" v-else>
          <span v-for="i in keyLength" :key="i" class="dot" :class="checkActive(i)"></span>
        </div>
        <ion-img src="/assets/login/eye.svg" class="indicators-keypad-label" v-if="props.completed"
          @click="toggleVisible" />
      </div>
      <ion-grid class="ion-text-center">
        <ion-row>
          <ion-col size="4" v-for="key in keys" :key="key">
            <button :class="checkNumeric(key) ? 'button-keypad' : 'button-keypad button-keypad-clear'"
              @click="keypadUpdate(key)" :disabled="checkNumeric(key) ? keypadDisabledNumeric : keypadDisabledAll">
              <template v-if="key == 'delete'">
                <ion-img src="/assets/general/keypad-delete.svg" class="w-[24px] mx-auto" :title="key" />
              </template>
              <template v-else>
                <span class="uppercase">{{ key }}</span>
              </template>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
const props = defineProps(['completed', 'match', 'confirmed', 'count', 'loading', 'type', 'length']);
const emit = defineEmits(['keypadChange', 'keypadComplete']);
const visible = ref(false);
const shake = computed(() => props.type == 'check' && props.completed && props.confirmed && !props.match ? 'shake' : '');
const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'clear', '0', 'delete'];
const keyLength = computed(() => Number(props.length));
const keypadEntered = ref('');
const keypadEnteredCount = computed(() => [...keypadEntered.value].length);
const keypadDisabledNumeric = computed(() => (props.loading || (props.match && props.confirmed)) || keypadEnteredCount.value >= keyLength.value);
const keypadDisabledAll = computed(() => (props.loading || props.confirmed || keypadEnteredCount.value == 0));
const keypadUpdate = (key) => {
  if (key == 'clear') {
    keypadClear();
  } else if (key == 'delete') {
    keypadDelete();
  } else {
    keypadAdd(key);
  }
}
const keypadAdd = (n) => {
  keypadEntered.value += n;
};
const keypadClear = () => {
  keypadEntered.value = '';
};
const keypadDelete = () => {
  keypadEntered.value = keypadEntered.value.slice(0, -1);
}
const checkActive = (i) => [...keypadEntered.value][i - 1] ? 'active' : '';
const checkNumeric = (i) => !['delete', 'clear'].includes(i);
const toggleVisible = () => {
  if (!(props.loading || props.match)) {
    visible.value = !visible.value;
  }
}
defineExpose({ keypadClear });
watch(() => keypadEnteredCount.value, () => {
  visible.value = false;
  emit('keypadChange', keypadEntered.value);
  if (keypadEnteredCount.value == keyLength.value) {
    emit('keypadComplete', keypadEntered.value);
  }
}, { immediate: true });
</script>