import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

export function useSecureStorage() {
  const keys = {
    otp: "otp",
    bio: "bio",
    pro: "pro",
    name: "name",
    attempts: "attempts",
  };
  const setStorage = (obj) => {
    if (Capacitor.isNativePlatform()) {
      SecureStoragePlugin.set(obj);
    }
  };
  const getStorage = async (obj) => {
    if (Capacitor.isNativePlatform()) {
      return await SecureStoragePlugin.get(obj);
    }
  };
  const getPlatform = async () => {
    if (Capacitor.isNativePlatform()) {
      return await SecureStoragePlugin.getPlatform();
    }
  };
  const removeStorage = (obj) => {
    if (Capacitor.isNativePlatform()) {
      SecureStoragePlugin.remove(obj);
    }
  };
  const clearAllStorage = () => {
    if (Capacitor.isNativePlatform()) {
      SecureStoragePlugin.clear()
        .then((res) => console.log("storage cleared: " + res.value))
        .catch((err) => console.log(err));
    }
  };

  return {
    keys,
    setStorage,
    getStorage,
    getPlatform,
    removeStorage,
    clearAllStorage,
  };
}
