<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header :hideBackButton="true"></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Password</h2>
        <register-steps step="5"></register-steps>
        <div class="ion-item-wrapper">
          <ion-item class="default" lines="none">
            <ion-label position="floating">Password</ion-label>
            <ion-input type="password" ref="inputPassword" v-model="password" @input="handleInputPassword"
              @ionChange="handleInputPassword"></ion-input>
            <ion-img src="/assets/login/eye.svg" class="absolute top-11 right-4 z-10"
              :class="showPassword ? 'opacity-100' : 'opacity-40'" @click="toggleShowPassword"
              v-if="password"></ion-img>
          </ion-item>
        </div>
        <div class="ion-item-wrapper mt-6">
          <ion-item class="default" lines="none">
            <ion-label position="floating">Confirm Password</ion-label>
            <ion-input type="password" ref="inputPasswordConfirmed" v-model="passwordConfirmed"
              @input="handleInputPasswordConfirmed" @ionChange="handleInputPasswordConfirmed"
              :disabled="!validated"></ion-input>
            <ion-img src="/assets/login/eye.svg" class="absolute top-11 right-4 z-10"
              :class="showPasswordConfirmed ? 'opacity-100' : 'opacity-40'" @click="toggleShowPasswordConfirmed"
              v-if="passwordConfirmed"></ion-img>
          </ion-item>
        </div>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-6 ion-text-primary block">{{ passwordError }}</ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 mb-6 ion-text-primary block">{{ matchError }}</ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 my-3 ion-text-primary block" v-if="passwordSentError">{{
          passwordSentError }}</ion-note>
        <button class="button-purple button-register mt-6 px-6"
          @click="btnNext('register-success', urls.apiSignUpStepPassword)"
          :disabled="!entered || !confirmed || loading">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" class="absolute -ml-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold text-white">
            Next <img :src="imgNext" class="float-right ml-2 mt-2" />
          </span>
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import { useField } from 'vee-validate';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import axios from 'axios';
import { useRegisterStore, useCustomerStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { storeToRefs } from 'pinia';
import { useMixed } from '@/composables/mixed';
const { fieldValidation } = useMixed();
const { urls } = useBrowser();
const registerStore = useRegisterStore();
const { stepFourVerified } = storeToRefs(registerStore);
const { setPassword, setPasswordConfirmed, setStepFiveCompleted, clearPrefill } = registerStore;
const customerStore = useCustomerStore();
const { deviceID, sessionID, environment } = storeToRefs(customerStore);
const { setAuthToken, setEnvironment, updateAuth, checkExistingAccount } = customerStore;
const passwordConfirmed = ref('');
const passwordSentError = ref('');
const inputPassword = ref();
const inputPasswordConfirmed = ref();
const showPassword = ref(false);
const showPasswordConfirmed = ref(false);
const entered = computed(() => password.value !== '' && passwordConfirmed.value !== '');
const confirmed = computed(() => password.value == passwordConfirmed.value);
const matchError = computed(() => entered.value && !confirmed.value ? fieldValidation.password.msgMatch : '');
const imgNext = computed(() => entered.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const validated = ref(false);
const loading = ref(false);
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputPassword.value.$el.type = showPassword.value ? 'text' : 'password';
}
const toggleShowPasswordConfirmed = () => {
  showPasswordConfirmed.value = !showPasswordConfirmed.value;
  inputPasswordConfirmed.value.$el.type = showPasswordConfirmed.value ? 'text' : 'password';
}
const toggleReset = () => {
  showPassword.value = false;
  showPasswordConfirmed.value = false;
  inputPassword.value.$el.type = 'password';
  inputPasswordConfirmed.value.$el.type = 'password';
}
const handleInputPassword = () => {
  passwordSentError.value = '';
  passwordConfirmed.value = '';
  toggleReset();
}
const handleInputPasswordConfirmed = () => {
  passwordSentError.value = '';
  toggleReset();
}
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value) {
    validated.value = false;
    return fieldValidation.password.msgRequire;
  }

  if (value.length < 6) {
    validated.value = false;
    return fieldValidation.password.msgLength;
  }

  const regex = fieldValidation.password.regex;
  if (!regex.test(String(value)) || value.length < 6) {
    validated.value = false;
    return fieldValidation.password.msgValidate;
  } else {
    validated.value = true;
    return true;
  }

});

const btnNext = (next, url) => {
  loading.value = true;
  axios.post(url, {
    device_id: deviceID.value,
    session_id: sessionID.value,
    password: password.value,
    password_confirmation: passwordConfirmed.value,
    environment: environment.value
  })
    .then((res) => {
      loading.value = false;
      if (res.status == 200) {
        setPassword(password.value);
        setPasswordConfirmed(true);
        setStepFiveCompleted(true);
        setAuthToken(res.data.auth_token);
        setEnvironment(res.data.environment)
        updateAuth(true);
        router.push(next);
      }
    })
    .catch((err) => {
      loading.value = false;
      passwordSentError.value = err.response.data.message;
      checkExistingAccount(err.response.status, err.response.data.message, clearPrefill);
    });
}

onMounted(() => {
  if (!stepFourVerified.value) {
    router.push('register-step-1');
  }
});
</script>