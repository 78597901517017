<template>
  <ion-card class="fixed w-full bg-dark-purple ion-card-no-shadow button-start button-start-animation"
    :class="screenSizeSmall ? 'p-5' : 'p-8 pt-6'">
    <ion-card-content class="p-0">
      <button class="button-green mb-4 start-buttons" :class="screenSizeSmall ? 'button-green-md' : ''"
        @click="checkIfQualify">
        <span class="block ion-text-white font-semibold" :class="screenSizeSmall ? 'text-md' : 'text-lg'">
          Need a loan?
          <small class="block text-[10px]" :class="screenSizeSmall ? '-mt-1' : '-mt-3 -mb-1'">
            Check If You Qualify
          </small>
        </span>
      </button>
      <button class="button-yellow mb-4" :class="screenSizeSmall ? 'button-yellow-md' : ''" @click="trackCreditScore">
        <span class="block ion-text-dark-purple font-semibold" :class="screenSizeSmall ? 'text-md' : 'text-lg'">
          Track your credit score
          <small class="block text-[10px]" :class="screenSizeSmall ? '-mt-1' : '-mt-3 -mb-1'">
            For Free!
          </small>
        </span>
      </button>
      <div v-if="loggedOut || selfBanned">
        <button class="w-full text-center" fill="clear" @click="$router.push('login-email')">
          <span class="ion-text-white font-bold">Log in</span>
        </button>
      </div>
      <div v-else>
        <button class="w-full text-center" fill="clear" @click="onAuthenticate" v-if="logInWithBio">
          <span class="ion-text-white capitalize font-bold fadein-900">{{ biometryLabel }}</span>
          <ion-icon src="/assets/general/arrow-white-2.svg" class="pl-1 h-[11px]"></ion-icon>
        </button>
        <button class="w-full text-center" fill="clear" @click="$router.push('login-pin')" v-else-if="logInWithPin">
          <span class="ion-text-white capitalize font-bold fadein-900">Login with PIN</span>
          <ion-icon src="/assets/general/arrow-white-2.svg" class="pl-1 h-[11px]"></ion-icon>
        </button>
        <button class="w-full text-center" fill="clear" @click="$router.push('login-email')" v-else>
          <span class="ion-text-white font-bold">Log in</span>
          <ion-icon src="/assets/general/arrow-white-2.svg" class="pl-1 h-[11px]"></ion-icon>
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { computed, watch } from 'vue';
import { asyncComputed } from '@vueuse/core';
import { useSecureStorage } from '@/composables/secureStorage';
import { useBiometryAuth } from '@/composables/biometryAuth';
import { useCustomerStore, useConfigStore } from '@/stores';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import { storeToRefs } from 'pinia';
import router from '@/router';
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const { updateOtp, updateBio, setStartCheckIfQualify, setStartTrackCreditScore } = customerStore;
const { selfBanned, loggedOut } = storeToRefs(customerStore);
const { screenSizeSmall } = storeToRefs(configStore);
const emit = defineEmits(['logInWith']);
const { onAuthenticate, biometryAvailCheck, biometryLabel } = useBiometryAuth();
const { keys, getStorage } = useSecureStorage();
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const bioStorage = asyncComputed(async () => await getStorage({ key: keys.bio }), null);
const proStorage = asyncComputed(async () => await getStorage({ key: keys.pro }), null);
const logInWithBio = computed(() => validToken && bioStorage.value && biometryAvailCheck.value ? true : false);
const logInWithPin = computed(() => validToken && otpStorage.value && !logInWithBio.value ? true : false);
const checkBio = computed(() => bioStorage.value && biometryAvailCheck.value ? true : false);
const checkPin = computed(() => otpStorage.value ? true : false);
const validToken = async () => proStorage.value && { ...JSON.parse(proStorage.value.value) }.token;
const getStartedClicked = () => {
  trackEvent(AdjustEvents.CLICK_GET_STARTED);
  return true;
}
const checkIfQualify = () => {
  setStartCheckIfQualify(true);
  setStartTrackCreditScore(false);
  getStartedClicked(); // may need to update adjust
  router.push('register-step-1');
}
const trackCreditScore = () => {
  setStartCheckIfQualify(false);
  setStartTrackCreditScore(true);
  getStartedClicked(); // may need to update adjust
  router.push('register-step-1');
}
watch([checkPin, checkBio], () => {
  updateOtp(checkPin.value ? true : false);
  updateBio(checkBio.value ? true : false);
  emit('logInWith', checkPin.value || checkBio.value);
}, { immediate: true });
</script>

<style scoped>
.button-start {
  max-width: 428px;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto;
  bottom: -300px;
  z-index: 9;
  border-top: #E6D6FF 5px solid;
}

.button-start-animation {
  animation: buttonstart 1s forwards;
}

@keyframes buttonstart {
  from {
    bottom: -300px;
  }

  to {
    bottom: 0px;
  }
}
</style>