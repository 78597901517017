<template>
  <ion-content>
    <div class="px-7 pt-6 max-w-xl mx-auto">
      <div>
        <div class="mt-8 mb-6">
          <ion-text class="text-lg font-bold ion-text-brand">Updates Saved</ion-text>
          <ion-text class="text-sm ion-text-brand mt-3 mb-6 block">
            Your communications have been {{ pauseMsg }}.
          </ion-text>
          <ion-text class="text-xs ion-text-brand mt-3 mb-6 block">
            Please note that Jacaranda will continue to contact you via the contact channels supplied regarding your
            active loans or products.
          </ion-text>
          <ion-text class="text-xs ion-text-brand mt-3 mb-6 block" v-if="!marketingPreferencesPauseUntil">
            You can change your communications preferences at any time in the previous screen.
          </ion-text>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import { useMixed } from '@/composables/mixed';
import { computed } from 'vue';
const { formatCustomDOB, formatMonthNumToStr } = useMixed();
const customerStore = useCustomerStore();
const { marketingPreferencesPause, marketingPreferencesPauseUntil, marketingPreferencesPauseDuration } = storeToRefs(customerStore);
const pauseDuration = computed(() => `${formatMonthNumToStr(marketingPreferencesPauseDuration.value)} (${marketingPreferencesPauseDuration.value}) ${marketingPreferencesPauseDuration.value > 1 ? 'months' : 'month'}`);
const pauseUntil = computed(() => `and will restart on ${formatCustomDOB(marketingPreferencesPauseUntil.value)}`);
const pauseMsg = computed(() => marketingPreferencesPause.value ? `paused for ${pauseDuration.value} ${pauseUntil.value}` : `updated`)
</script>